<template>
  <div>
    <b-row>
      <b-col md="8">
        <b-form-group :label="$t('Alias')">
          <validation-provider
            #default="{ errors }"
            name="alias"
            rules="required"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="UserPlusIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.alias"
                :placeholder="$t('Alias')"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('Account Holder')">
          <validation-provider
            #default="{ errors }"
            name="bank account holder"
            rules="required"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="ColumnsIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.accountHolder"
                :placeholder="$t('Account Holder')"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group :label="$t('Account Number')">
          <validation-provider
            #default="{ errors }"
            name="account number"
            rules="required"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="MailIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.bankAccount"
                :placeholder="$t('Account Number')"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Bank Name')">
          <validation-provider
            #default="{ errors }"
            name="bank name"
            rules="required"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="UserCheckIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.bankName"
                :placeholder="$t('Bank Name')"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Bank Address')">
          <validation-provider
            #default="{ errors }"
            name="bank address"
            rules="required"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="ServerIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.bankAddress"
                :placeholder="$t('Bank Address')"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group :label="$t('Bank Swift Code')">
          <validation-provider
            #default="{ errors }"
            name="bank swift code"
            rules="required|swiftCode"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="CodeIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.bankSwiftCode"
                :placeholder="$t('Bank Swift Code')"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group :label="$t('Bank IBAN Code (Optional)')">
          <validation-provider
            #default="{ errors }"
            name="bank IBAN code"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="CodeIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.bankIbanCode"
                :placeholder="$t('Bank IBAN Code')"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group :label="$t('Country')">
          <validation-provider
            #default="{ errors }"
            name="country"
            rules="required"
          >
            <b-input-group>
              <v-select
                id="countryList"
                v-model="form.country"
                :placeholder="$t('Select a Country')"
                style="width: 100%"
                label="name"
                :reduce="item => item.code"
                :options="countryOption"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="12"
        class="mt-2 text-right px-0"
      >
        <b-button
          variant="secondary"
          :disabled="isProcessing"
          @click="$emit('cancel')"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          {{ $t('Cancel') }}
        </b-button>

        <b-button
          variant="primary"
          type="submit"
          class="mx-1 mx-md-2"
          :disabled="isProcessing"
          @click.prevent="$emit('validationForm',form)"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          {{ $t('Save Changes') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormInput, BFormGroup, BRow, BCol, BButton, BInputGroup, BInputGroupPrepend, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BSpinner,
    vSelect,
  },
  props: {
    countryOption: {
      type: [Array, Object],
      default: () => {},
    },
    isProcessing: {
      type: [Boolean, String],
      default: () => {},
    },
    formData: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
    }
  },

  mounted() {
    if (this.formData) {
      this.form = this.formData
    }
  },
}
</script>
