<template>
  <b-card>
    <b-overlay :show="isProcessing">
      <!-- form -->
      <b-form @submit.prevent="changePassword">
        <validation-observer ref="changePasswordForm">
          <button
            class="d-none"
            type="submit"
          />
          <b-row>
            <!-- old password -->
            <b-col md="6">
              <b-form-group
                :label="$t('Old Password')"
                label-for="account-old-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="old password"
                  vid="password_old"
                  rules="required|min:8"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="account-old-password"
                      v-model="passwordValueOld"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      name="old-password"
                      autocomplete="off"
                      :type="passwordFieldTypeOld"
                      :placeholder="$t('Old Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconOld"
                        class="cursor-pointer"
                        @click="togglePasswordOld"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ old password -->
          </b-row>
          <b-row>
            <!-- new password -->
            <b-col md="6">
              <b-form-group
                label-for="account-new-password"
                :label="$t('New Password')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="new password"
                  vid="password"
                  :rules="`required|min:8|different-password:${passwordValueOld}`"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="account-new-password"
                      ref="password"
                      v-model="newPasswordValue"
                      :type="passwordFieldTypeNew"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      name="new-password"
                      autocomplete="off"
                      :placeholder="$t('New Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <password-strength :password-strength="passwordStrength" />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ new password -->

            <!-- retype password -->
            <b-col md="6">
              <b-form-group
                label-for="account-retype-new-password"
                :label="$t('Retype New Password')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="confirm password"
                  vid="password_confirmation"
                  rules="required|min:8|confirmed:password"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="account-retype-new-password"
                      v-model="RetypePassword"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :type="passwordFieldTypeRetype"
                      name="retype-password"
                      :placeholder="$t('New Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ retype password -->

            <!-- buttons -->
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                class="mt-1 mr-1"
              >
                {{ $t('Reset') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1"
                @click.prevent="changePassword"
              >
                {{ $t('Save Changes') }}
              </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </validation-observer>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useApollo from '@/plugins/graphql/useApollo'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate/dist/vee-validate.full'
import PasswordStrength from '@/views/common/components/PasswordStrength.vue'

extend('different-password', (value, [currentPassword]) => {
  if (value === currentPassword) return 'The new password cannot be the same as the current password'
  return true
})

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    PasswordStrength,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      isProcessing: false,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordStrength() {
      return this.PASSWORD_STRENGTH(this.newPasswordValue)
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    changePassword() {
      this.$refs.changePasswordForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.auth.changePassword({
            old_password: this.passwordValueOld,
            password: this.newPasswordValue,
            password_confirmation: this.RetypePassword,
          }).then(() => {
            //  this.optionsLocal = response.data
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notice',
                icon: 'CoffeeIcon',
                variant: 'info',
                text: 'Your password has been successfully updated',
              },
            }, {
              position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
            })
          }).catch(error => {
            console.log(error)
            if (error.response?.data?.errors) this.$refs.changePasswordForm.setErrors(error.response.data.errors)
            else if (error.graphQLErrors[0]?.extensions?.errors?.password) this.showErrorMessage(error.graphQLErrors[0].extensions.errors.password)
            else if (error.graphQLErrors[0]?.extensions?.validation['input.password']) this.showErrorMessage(error.graphQLErrors[0].extensions.validation['input.password'])
            else this.showErrorMessage(error)
          }).finally(() => { this.isProcessing = false })
        }
      })
    },

  },
}
</script>
