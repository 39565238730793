<template>
  <b-tabs
    v-model="activeTab"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Contact Details') }}</span>
      </template>
      <b-overlay :show="isProcessing">
        <account-setting-general
          :country-option="countries"
          :user-info-data="extraInformations.filter(extra => extra.__typename === 'ContactInformation')"
          @refetch="getUserSettings"
        />
      </b-overlay>
    </b-tab>
    <!--/ general tab -->
    <!-- banking -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="DollarSignIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Bank Details') }}</span>
      </template>
      <b-overlay :show="isProcessing">
        <account-setting-banking
          :country-option="countries"
          :user-info-data="extraInformations.filter(extra => extra.__typename === 'BillingInformation' || extra.__typename === 'AchBillingInformation' ||extra.__typename === 'FxBillingInformation' )"
          @refetch="getUserSettings"
        />
      </b-overlay>
    </b-tab>
    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Change Password') }}</span>
      </template>
      <account-setting-password />
    </b-tab>

    <!--/ change password tab -->

    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="FileTextIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('kyc.title') }}</span>
      </template>
      <kyc />
    </b-tab>

  </b-tabs>
</template>

<script>
import { BTabs, BTab, BOverlay } from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import AccountSettingGeneral from './components/AccountSettingGeneral.vue'
import AccountSettingPassword from './components/AccountSettingPassword.vue'
import AccountSettingBanking from './components/AccountSettingBanking.vue'
import Kyc from './components/Kyc.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingBanking,
    Kyc,
    BOverlay,
  },
  data() {
    return {
      options: {},
      extraInformations: [],
      isProcessing: false,
      activeTab: 0,
      countries: [],
    }
  },
  created() {
    const selectedMenu = this.$route.hash ? this.$route.hash.split('#')[1] : 'general'
    if (selectedMenu === 'banking') {
      this.activeTab = 1
    } else if (selectedMenu === 'project') {
      this.activeTab = 3
    }
    this.getCountry()
    this.getUserSettings()
  },
  methods: {
    getUserSettings() {
      this.isProcessing = true
      useApollo.users.getGeneralSetting().then(response => {
        const { extraInformations } = response.data.me
        const extraInformationData = extraInformations.data.map(info => ({ ...info.information }))
        this.extraInformations = extraInformationData
      }).finally(() => { this.isProcessing = false })
    },
    getCountry() {
      useApollo.company.getCountries().then(response => {
        this.countries = response.data.countries.data
      })
    },
    addHashToRoute(value) {
      window.location.hash = value
    },
  },

}
</script>
