<template>
  <!-- Add New Info -->
  <div>
    <h6
      v-if="!documentImages.length"
      class="text-success"
    >
      {{ infoText }}
    </h6>
    <b-row class="px-2">
      <div
        v-for="document,i in documentImages"
        :key="i"
        class="col-md-6 col-sm-12 mt-2"
      >

        <b-form-group :label="`${documentOptions[document.documentType]}'s Image`">
          <div
            v-if="!document.verifiedAt"
            class="text-right"
          >
            <feather-icon
              v-b-tooltip.hover
              icon="TrashIcon"
              :title="$t('Remove Document')"
              size="18"
              class="text-danger cursor-pointer m-1"
              @click="removeDocument(i,document.id || null)"
            />
          </div>
          <custom-image-upload
            v-if="!document.verifiedAt"
            height="220"
            :url="document.document && document.document.includes('kyc/') ? getStorage(document.document) : document.document"
            @imageUploaded="(img,url)=>{ addDocumentImages(document.documentType,url) }"
            @imageRemoved="()=>{
              removeDocumentImages(document.documentType)
            }"
          />
          <div
            v-else
            style="height:300px;"
            class="position-relative"
          >
            <img
              :src="getStorage(document.document)"
              style="object-fit: cover;"
              class="h-100 w-100"
            >
            <img
              v-b-tooltip.hover
              src="@/assets/images/new-icons/verified.png"
              width="40px"
              class="position-absolute"
              height="40"
              title="Verified"
              style="right:0; top:0;"
            >
          </div>

        </b-form-group>
      </div>

      <div
        v-if="Object.keys(documentOptions).length !== documentImages.length"
        class="add-document-card text-center cursor-pointer col-md-6 col-sm-12 mt-2"
      >
        <b-row class="justify-content-center h-100 align-items-center">
          <b-col
            v-for="document,i in documentOptions"
            v-if="!notContains(i)"
            :key="i"
            md="5"
            class="my-1"
            sm="12"
            @click="addDocument(i)"
          >
            <div
              class="document-card p-2"
              :class="getRandomColor(document).color"
            >
              <img :src="require(`@/assets/images/new-icons/${i}.png`)">
              <span class="d-block">
                <strong>{{ document }}</strong>
              </span>

            </div>
          </b-col>
        </b-row>
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup, BCol, BRow, VBTooltip,
} from 'bootstrap-vue'
import CustomImageUpload from '../../components/CustomImageUpload.vue'

export default {
  components: {

    BFormGroup,
    BRow,
    BCol,
    CustomImageUpload,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    documentOptions: {
      type: [Array, Object],
      default: () => {},
    },
    documentImages: {
      type: [Array, Object],
      default: () => {},
    },
    infoText: {
      type: [String],
      default: '',
    },
  },

  data() {
    return {
      idImage: null,
      idCardOption: null,
      addressProofOption: null,
      creating: true,
      uploadingDocuments: [],
    }
  },

  methods: {
    addDocument(document) {
      this.uploadingDocuments.push(document)
      this.documentImages.push(
        { documentType: document, document: null },
      )
    },

    removeDocument(index, id = null) {
      this.uploadingDocuments.splice(index, 1)
      this.documentImages.splice(index, 1)
      if (id) {
        this.$emit('documentRemoved', id)
      }
    },

    addDocumentImages(key, image) {
      this.documentImages.find(e => e.documentType === key).document = image
    },

    removeDocumentImages(key) {
      this.documentImages.find(e => e.documentType === key).document = null
    },

    notContains(key) {
      const foundDocument = this.documentImages.find(e => e.documentType === key)
      return !!foundDocument
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/wallet-page.scss";

.document-card{
  border:1px dashed #7367f0;
  padding:10px;
  min-height: 60px;
  border-radius:10px;
  transition: transform 0.3s ease-in;
  &:hover{
    background: #d8fed8;
    transform: scale(1.1);
  }
  img{
    height:40px;
    width:40px;
  }
}

.add-document-card{
  border:1px dashed #7367f0;
  padding:10px;
  border-radius: 10px;
  margin-top:10px;
}

</style>
